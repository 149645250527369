<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5 d-flex justify-space-between">
        <slot name="title">
          Tax Scheme
        </slot>

        <v-icon @click="onClose">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col :cols="12">
            <translation-locales />
          </v-col>
          <v-col :cols="12">
            <CountryIdFilter
              v-model="form"
              :clearable="false"
              class="mb-5"
            />

            <translatable-input
              v-model="form.name"
              label="Display Name"
            >
            </translatable-input>

            <v-text-field
              v-model="form.percentage"
              type="number"
              label="Percentage"
            >
            </v-text-field>

            <v-row>
              <v-col :cols="6">
                <v-switch
                  v-model="form.active"
                  color="primary"
                  :label="form.active ? 'Active' : 'Inactive'"
                  :disabled="disabledFields.indexOf('active') >= 0"
                ></v-switch>
              </v-col>
              <v-col :cols="6">
                <v-switch
                  v-model="form.main"
                  color="primary"
                  :label="form.main ? 'Main' : 'Not Main'"
                  :disabled="disabledFields.indexOf('main') >= 0"
                ></v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <slot name="channels"></slot>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { CountryIdFilter, TranslatableInput, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: {
    TranslationLocales,
    TranslatableInput,
    CountryIdFilter,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    resource: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false)

    const useFieldValue = (fieldName, defaultValue) => (props.resource ? props.resource[fieldName] : defaultValue)

    const defaultFormValue = {
      name: useFieldValue('name', useTranslatable()),
      percentage: useFieldValue('percentage', 8),
      active: useFieldValue('active', true),
      main: useFieldValue('main', false),
      country_id: useFieldValue('country_id', null),
    }

    const form = ref({
      ...defaultFormValue,
    })

    const formRef = ref(null)

    const onSubmit = () => {
      if (formRef.value.validate()) {
        console.log(form.value)

        emit('submit', form.value)
      }
    }

    const onClose = () => {
      if (isEditing.value) {
        formRef.value.resetValidation()
      } else {
        formRef.value.reset()

        Object.assign(form.value, defaultFormValue)
      }

      emit('close')
    }

    return {
      isEditing,

      form,
      formRef,

      // Rules
      validators: {
        required,
      },

      t,
      onSubmit,
      onClose,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>

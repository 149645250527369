var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-section text-no-wrap",attrs:{"headers":_vm.columns,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.itemsTotal,"loading":_vm.loading,"header-props":_vm.headerprops},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.t(item.name)))])]}},{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.percentage))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? 'Yes' : 'No')+" "),(item.main)?_c('v-chip',{staticClass:"v-chip-light-bg primary--text font-weight-semibold",attrs:{"small":""}},[_vm._v(" Main ")]):_vm._e()]}},{key:"item.country_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.country.cca3_code)+" "),(!item.country.active)?_c('v-chip',{attrs:{"color":"info","small":""}},[_vm._v(" INACTIVE ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[(_vm.$can('tax_scheme:update', 'tax_scheme_management'))?_c('edit-dialog',{attrs:{"resource":item},on:{"updated":_vm.paginate}}):_vm._e()],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
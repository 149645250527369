<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    persistent
    full-height
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <v-btn
        color="success"
        outlined
        class="mb-1 me-3"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          size="17"
          class="me-1"
        >
          {{ icons.mdiPlus }}
        </v-icon>
        <span>Create</span>
      </v-btn>
    </template>

    <!-- Dialog Content -->
    <tax-scheme-form
      ref="formRef"
      :loading="loading"
      :disabled-fields="disabledFields"
      @close="onClose"
      @submit="onSubmit"
    />
  </v-dialog>
</template>

<script>
import { useNotifyErrors, useNotifySuccess } from '@/composables'
import { mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { taxSchemeService } from '../../services'
import TaxSchemeForm from './TaxSchemeForm.vue'

export default {
  components: {
    TaxSchemeForm,
  },
  props: {
    disabledFields: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const isDialogVisible = ref(false)
    const loading = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const onSubmit = async data => {
      loading.value = true

      await taxSchemeService.store({
        ...data,
      }).then(() => {
        useNotifySuccess({
          content: 'Tax Scheme has been created successfully.',
        })

        isDialogVisible.value = false

        emit('created')
      }).catch(error => {
        useNotifyErrors(error)
      }).finally(() => {
        loading.value = false
      })
    }

    return {
      formRef,
      isDialogVisible,
      loading,

      onClose,
      onSubmit,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>

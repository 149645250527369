<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <template #[`item.name`]="{item}">
        <span>{{ t(item.name) }}</span>
      </template>

      <template #[`item.percentage`]="{item}">
        <span>{{ item.percentage }}</span>
      </template>

      <template #[`item.active`]="{item}">
        {{ item.active ? 'Yes' : 'No' }}
        <v-chip
          v-if="item.main"
          class="v-chip-light-bg primary--text font-weight-semibold"
          small
        >
          Main
        </v-chip>
      </template>

      <template #[`item.country_id`]="{item}">
        {{ item.country.cca3_code }}
        <v-chip
          v-if="!item.country.active"
          color="info"
          small
        >
          INACTIVE
        </v-chip>
      </template>

      <!-- date -->
      <template #[`item.created_at`]="{item}">
        <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <edit-dialog
                v-if="$can('tax_scheme:update', 'tax_scheme_management')"
                :resource="item"
                @updated="paginate"
              />
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiDotsVertical } from '@mdi/js'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import EditDialog from './EditDialog.vue'

dayjs.extend(utc)

export default {
  components: {
    EditDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loading, options, paginate, items, itemsTotal, headerprops,
    } = props.tableList

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Active', value: 'active' },
      { text: 'Country', value: 'country_id' },
      { text: 'Percentage', value: 'percentage' },
      { text: 'Created At', value: 'created_at' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    return {
      t,
      dayjs,
      formatDate,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
